form {
  display: flex;
  flex-direction: column;
  width: 300px; /* Adjust as needed */
  margin: auto;
  align-items: center;
  
}

form div {
  margin-bottom: 10px;
}

label {
  margin-bottom: 5px;
  display: inline-block;
}

input {
  width: 300px;
  height: 25px;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 16px;
}

button {
  width: 344px;
  height: 45px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4B39EF;
  color: white;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 20px;
}

button:hover {
  background-color: darkblue;
}
