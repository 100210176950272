.background {
    position: relative; /* Makes this a relative container for absolute children */
    width: 100%; /* Adjust as needed */
    height: 896px;
    align-items: center;
    align-content: center;
    overflow: hidden; /* Optional: to hide overflowed parts of SVGs */
}

.background svg {
    position: absolute; /* Absolutely position SVGs */
    top: 0; /* Adjust as needed */
    left: 0; /* Adjust as needed */
    /* Add other styles as needed, e.g., width and height */
}

/* Optionally, specify styles for individual SVGs if they differ */
.background svg:first-child {
    width: 584.693px;
    height: 303.051px;
    flex-shrink: 0;
    fill: var(--Primary-Color, #004852);
}
