/* In Button.css or your existing CSS file */

.goButton {
    background-color: #4B39EF; /* Example color */
    color: #FFF;
    border-radius: 14px;
    width: 100%;
    height: 67px;
    flex-shrink: 0;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-items: center;
    justify-content: center; /* Center the text block */
    margin-top: 20px;
  }
  
  .alternateButton {
    background-color: #4B39EF; /* Example color */
    color: #FFF;
    border-radius: 14px;
    width: 100%;
    height: 35px;
    flex-shrink: 0;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-items: center;
    justify-content: center; /* Center the text block */
    margin-top: 10px;
  }

  .goButton:hover {
    background-color: #4431ee; /* Darker shade for hover effect */
  }
  