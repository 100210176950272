.page-container {
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  height: 103vh; /* Adjust if you want the container to fill the screen height */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #111827;
}

.App-footer {
color: #3E3E3E;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.17px;
}

.App-footer.login {
  color: var(--Black, #111827);
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.17px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
